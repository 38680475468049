<template>
  <div class="xingzaiShimmer">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>
    <!--内容-->
    <div class="xingzaiShimmerCon clearfix">
      <!--内容头部-->
      <div class="commenHead" style="height: 83.333vw;">
        <div class="about_img">
          <div class="right_grid ">
            <img src="../assets/images/pic_grid_r.png" alt="">
          </div>
          <div class="left_grid " style="height: auto;">
            <img src="../assets/images/pic_grid_l.png" alt="">
          </div>
        </div>
        <div class="about_tit">
          <p v-if="xingzaiData.title&&xingzaiData.title.length>0" class="animated bounceInLeft" v-html="xingzaiData.title[0].title"></p>
          <p v-if="xingzaiData.title&&xingzaiData.title.length>0" class="animated bounceInLeft" v-html="xingzaiData.title[1].title"></p>
          <p v-if="xingzaiData.title&&xingzaiData.title.length>0" class="animated bounceInLeft" v-html="xingzaiData.title[2].title"></p>
<!--          <p v-if="xingzaiData.title&&xingzaiData.title.length>0" class="animated bounceInLeft xing_text">-->
<!--            <span v-for="(item,index) in xingzaiData.title" :key="index" v-html="item.content"></span></p>-->
        </div>
      </div>
      <!--chuangxin-->
      <div class="xingzaiShimmerBefore animated bounceInRight"></div>
      <div class="xingzaiShimmerBox clearfix">
        <div class="animated bounceInLeft tit clearfix">
          <div class="topLine"></div>
          <p class="tit_text1" v-if="xingzaiData.title1&&xingzaiData.title1.length>0" v-html="xingzaiData.title1[0].title"></p>
        </div>
        <div class="animated bounceInLeft texts clearfix">
          <div>
            <p class="tit_text2" v-if="xingzaiData.title1&&xingzaiData.title1.length>0" v-html="xingzaiData.title1[0].content"></p>
          </div>
        </div>
      </div>

<!--      创不凡-->
      <div class="chuangBuFanBox clearfix">
        <div class="animated bounceInLeft tit clearfix">
          <div class="topLine"></div>
          <p class="tit_text" v-if="xingzaiData.title2&&xingzaiData.title2.length>0" v-html="xingzaiData.title2[0].title">>聚微光·创不凡</p>
        </div>
        <div class="animated bounceInLeft texts clearfix">
          <div>
            <p class="tit_text2" v-if="xingzaiData.title2&&xingzaiData.title2.length>0" v-html="xingzaiData.title2[0].content"></p>
          </div>
        </div>
        <div class="pics clearfix" v-show="picList&&picList.length>0">
          <div class="pic_item" v-for="(item ,index) in picList" :key="item.id" @click="toShowLunbo(index)">
            <div>
              <img v-lazy="item.thumb_image" alt="" style="object-fit:cover;">
              <div class="pic_hasVideo" v-show="item.video_file&&item.video_file.length>0"></div>
            </div>
          </div>
        </div>
      </div>

      <!--    轮播弹出层-->
      <van-overlay :show="isMaskShow" @click="closeLunbo">
        <div class="picWrapper">
          <div class="swiper-container mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiperItem" v-for="item in allPicList" :key="item.id">
                <img v-lazy="item.pic_image" alt="">
                <div class="hasVideo" v-if="item.video_file&&item.video_file.length>0" @click="toVideo(item.video_file)"></div>
              </div>
            </div>
          </div>

        </div>
      </van-overlay>
      <!--    视频弹出层-->
      <van-overlay :show="isVideoShow" @click="closeVideo">
        <div class="videowrapper">
          <video-player class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="playerOptions">
          </video-player>
        </div>
      </van-overlay>

      <Copyright></Copyright>


    </div>
  </div>
</template>

<script>
import baseNav from "./baseNav";
import Copyright from "@/component/Copyright";
import {GetXingPics,GetIndexDoc} from "@/api/api";
import Swiper from 'swiper';

export default {
  data(){
    return{
    // 行在微光数据
      xingzaiData:{},
    //  展示图片数组
      picList:[],
      //全部图片数组
      allPicList:[],
      //轮播遮罩层显示
      isMaskShow:false,
      //视频遮罩层显示
      isVideoShow:false,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: ""  // url地址
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
    }
  },
  mounted() {
    this.toGetIndexDoc(9)
    this.togetXingPics()

  },
  methods:{
    //获取行在微光数据
    toGetIndexDoc(data){
      let _this=this
      GetIndexDoc(data)
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.xingzaiData=[]
              return
            }
            let apiIndex=res.data
            _this.xingzaiData=apiIndex
            console.log('-------')
            console.log(res.data)
          })
    },
    //获取图片数组
    togetXingPics(){
      let _this=this
      GetXingPics()
        .then(r=>{
          let res=r.data
          if(res.status!=1){
            _this.$message.error('获取图片信息失败')
            _this.allPicList=[]
            _this.picList=[]
            return
          }
          _this.allPicList=res.data
          _this.picList=res.data.slice(0,9)
          console.log('=======')
          console.log(res.data)
          console.log(_this.picList);
        })

    },
    //显示遮罩层
    toShowLunbo(i){
      new Swiper('.picWrapper .mySwiper',{
        loop: true,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        autoplay:false,
        initialSlide :i,
      })
      this.isMaskShow=!this.isMaskShow
    },
    //关闭轮播
    closeLunbo(){
      this.isMaskShow=false
      let _this=this
      setTimeout(function (){
        _this.clickPicList=[]
      })
    },
    //显示视频
    toVideo(data){
      this.playerOptions.sources[0].src = data
      this.isMaskShow=false
      this.isVideoShow = true
    },
    //关闭视频
    closeVideo(){
      let _this=this
      _this.isVideoShow = false
      this.isMaskShow=true
      setTimeout(function (){
        _this.playerOptions.sources[0].src =''
      },1000)
    }
  },

  components: {
    baseNav,
    Copyright
  }
}
</script>
<style lang="less" scoped>
.xingzaiShimmer{
  padding-top: 13.888vw;
  background-color: #071D42;
  width: 100%;
  overflow: hidden;

  .commenHead {
    height: auto;
    z-index: 2;
    .about_tit{
      >p{
        font-size: 5.555vw;
        line-height: 6.805vw;
        font-weight: 300;
        font-family: Montserrat-Light, Montserrat;
      }
    }
    .xing_text{
      margin-left: 6.666vw;
      margin-top: 2.2222vw;
      // font-size:2.777vw;
      font-size:3.333vw;
      line-height: 5.2777vw;
      font-weight: 400;
      text-align: justify;
      display: block;
      width: 85vw;
      color:rgba(255,255,255,0.65);
    }
  }
  .commenHead::after{
    position: absolute;
    top: 0;
    left: 0;
    content:'';
    display: inline-block;
    width: 100%;
    height: 83.3333vw;
    background:url("../assets/images/pic_banner_vivo.png") no-repeat -68.0555vw -2.777vw;
    background-size: 180%;
    z-index:-1

  }
  .xingzaiShimmerBox{
    //margin-top:12vw;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    .tit{
      animation-delay: .5s;
      padding-left: 6.666vw;
      .tit_text1{
        font-size: 5.555vw;
        font-weight: 300;
        color: #20BFD0;
        line-height: 6.805vw;
        font-family: Montserrat-Light, Montserrat;
      }
    }
    .texts{
      animation-delay: .5s;
      padding-left: 6.666vw;
      margin-top: 3.333vw;
      min-height: 40vw;
      >div:first-child{
        width: 44vw;
        .tit_text2{
          display: inline-block;
          width: 100%;
          font-size: 3.333vw;
          font-weight: 300;
          font-family: Montserrat-Light, Montserrat;
          color: #20BFD0;
          line-height: 4.722vw;
          color:rgba(255,255,255,0.65);
        }
      }
    }
  }
  .xingzaiShimmerBefore{
    width: 100%;
    animation-delay: 0.5s;
    position: relative;
  }
  .xingzaiShimmerBefore::before{
      content:'';
      width: 55.555vw;
      height: 55.555vw;
      display: inline-block;
      background: url("../assets/images/pic_chuangxinzhe.png") no-repeat left top;
      background-size: 100%;
      position: absolute;
      right: -16px;
      top: 22vw;
  }
  .chuangBuFanBox{
    width: 100%;
    margin-top: 16.666vw;
    overflow: hidden;
    .tit{
      animation-delay: 1s;
      margin:0 6.666vw;
    }
    .texts{
      margin: 0 6.666vw 3.333vw;
      display: inline-block;
      font-size: 3.333vw;
      font-weight: 400;
      //text-align: justify;
      line-height: 6.666vw;
      color: rgba(255, 255, 255, 0.65);
    }
    .tit_text2{
      display: inline-block;
      width: 100%;
      font-size: 3.333vw;
      font-family: Montserrat-Light, Montserrat;
      font-weight: 300;
      color: #20BFD0;
      //text-align: justify;
      line-height: 4.722vw;
      color:rgba(255,255,255,0.65);
    }

    .pics{
      margin:0 5.555vw 16.666vw;
      .pic_item{
        display: inline-block;
        width: 33.33%;
        div{
          width: 100%;
          box-sizing: border-box;
          height: calc(33.33vw - 3.611vw);
          padding:1.1111vw;
          position: relative;
          img{
            border-radius: 1.1111vw;
            width: 100%;
            height: 100%;
          }
          .pic_hasVideo{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;

          }
          .pic_hasVideo::after{
            content: '';
            width: 21%;
            height: 21%;
            background: url("../assets/images/icon_video.png") no-repeat center center ;
            background-size: cover;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
          }
        }

      }
    }
  }
  .picWrapper{
    margin-top: 50vh;
    transform: translateY(-50%);
    .swiperItem{
      position: relative;
      img{
        width: 100%;
      }
      .hasVideo{
        width: 100%;
        height: 100%;
        background: url("../assets/images/icon_video.png") no-repeat center center;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .videowrapper{
    margin-top: 50vh;
    transform: translateY(-50%);
  }
}
.tit_text{
  font-size: 5.555vw;
  font-weight: 300;
  color: #20BFD0;
  line-height: 6.805vw;
  font-family: Montserrat-Light, Montserrat;
}
</style>